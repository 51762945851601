import { useRef, Suspense } from "react";
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import SuspenseLoader from '@src/components/ui/SuspenseLoader';
import { SharedDataProvider } from '@src/providers/SharedDataProvider';
import { AuthProvider } from '@src/providers/AuthProvider';
import Sidebar from '@src/components/ui/Sidebar';
import Header from '@src/components/ui/Header';
import { SidebarProvider } from '@src/providers/SidebarProvider';
import { MainLayoutProvider } from '@src/providers/MainLayoutProvider';
import { ErrorBoundary } from '@sentry/react';
import Fallback from '@src/components/ui/Fallback';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

const ApplicationLayout = ({ notFound, ...props }) => {
  const breadcrumbsRef = useRef(null);
  const titleRef = useRef(null);
  const toolbarRef = useRef(null);
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <SharedDataProvider>
        <AuthProvider>
          <Box display="flex">
            <SidebarProvider>
              <Header titleRef={titleRef} toolbarRef={toolbarRef} />
              <Sidebar />
            </SidebarProvider>

            <MainLayoutProvider breadcrumbsRef={breadcrumbsRef} titleRef={titleRef} toolbarRef={toolbarRef}>
              <Suspense fallback={<SuspenseLoader />}>
                <ErrorBoundary fallback={(props) => <Fallback {...props} />}>
                  <Outlet />

                  {notFound && 'Not Found'}
                </ErrorBoundary>
              </Suspense>
            </MainLayoutProvider>
          </Box>
        </AuthProvider>
      </SharedDataProvider>
    </LocalizationProvider>
  );
};

export default ApplicationLayout;
