import ApplicationModel from '@src/models/ApplicationModel';
import Room from '@src/models/Room';
import sortBy from 'lodash/sortBy';

export default class RoomGroup extends ApplicationModel {
  constructor(attrs = {}) {
    super(attrs);
    this.rooms &&= sortBy(this.rooms, 'code').map((attr) => new Room(attr).references({ roomGroup: this }));
  }

  get property() {
    return this.associations.property;
  };

  set property(record) {
    this.associations.property = record;
  }

  get optionLabel() {
    return this.localized('name');
  }
}
