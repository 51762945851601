import { useState, useEffect } from 'react';
import { applicationData } from '@src/util/environment';

const script = document.createElement('script');
script.src = "https://apis.google.com/js/api.js";
script.async = true;
script.defer = true;
let clientInitialized = false;

export const useGAPI = () => {
  const [gapi, setGAPI] = useState(null);

  useEffect(() => {
    if (!script.parentNode) {
      script.onload = () => {
        window.gapi.load('client', async () => {
          await window.gapi.client.init({
            apiKey: applicationData.googleAPIKey,
          });

          clientInitialized = true;
          setGAPI(window.gapi);
        });
      };
      document.body.appendChild(script);
    } else if (!gapi && clientInitialized) {
      setGAPI(window.gapi);
    }
  }, []);

  return gapi;
};
