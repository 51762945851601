import { useState } from "react";
import {
  Alert,
  AlertTitle,
  Container,
  Slide,
  Snackbar,
  Typography,
} from '@mui/material';

const StandardSnackbar = ({ severity, reason, title, message, autoHideDuration, ...props }) => {
  const [lastReason, setLastReason] = useState(undefined);

  const dismiss = () => {
    setLastReason(reason);
  };

  if (reason instanceof Error) {
    severity ||= "error";
    title ||= reason.message;
    message ||= reason.info?.errorMessages ||
                reason.info?.exception ||
                reason.info?.error ||
                JSON.stringify(reason.info);
  } else {
    autoHideDuration ||= 5000;
  }

  return (
    <Snackbar
      open={reason && reason !== lastReason}
      onClose={dismiss}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
      autoHideDuration={autoHideDuration}
      {...props}
    >
      <Container maxWidth="sm">
        <Alert severity={severity || "info"} onClose={dismiss}>
          <AlertTitle>{title}</AlertTitle>
          <Typography variant="caption" sx={{ wordBreak: "break-all" }}>{message}</Typography>
        </Alert>
      </Container>
    </Snackbar>
  );
};

export default StandardSnackbar;
