import { localized } from '@src/util/i18n';
import getValue from 'lodash/get';
import dayjs from 'dayjs';

export default class ApplicationModel {
  static associations = new WeakMap;

  constructor(attrs = {}) {
    this.attributes = attrs;
    this.castDateAttributes('createdAt', 'updatedAt');
    ApplicationModel.associations.set(this, {});
  }

  localized(prop) {
    return localized(getValue(this, prop));
  }

  references(mapping) {
    this.associations = mapping;
    return this;
  }

  isChanged(attribute) {
    return this.attributes[attribute] !== this[attribute];
  }

  get associations() {
    return ApplicationModel.associations.get(this);
  }

  set associations(mapping) {
    return ApplicationModel.associations.set(this, mapping);
  }

  get attributes() {
    return this._initialAttributes;
  }

  set attributes(attrs = {}) {
    this._initialAttributes = attrs;

    for (let key in attrs) {
      this[key] = attrs[key];
    }
  }

  castDateAttributes(...attributes) {
    attributes.forEach((attr) => {
      if (this.hasOwnProperty(attr)) {
        if (this.attributes[attr]) {
          // restore from original string
          this[attr] = this.attributes[attr];
        } else if (this[attr]?.['$d']) {
          // restore from dayjs object
          this[attr] = this[attr]['$d'];
        }

        this[attr] = this[attr] && dayjs(this[attr]);
      }
    });
  }
}

