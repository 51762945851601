import transform from 'lodash/transform';
import set from 'lodash/set';
import isArray from 'lodash/fp/isArray';
import isPlainObject from 'lodash/fp/isPlainObject';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';

/**
 * Recursively camelize keys of a given object.
 */
export const camelCaseKeys = (obj) => {
  if (isArray(obj)) return obj.map(camelCaseKeys);
  if (isPlainObject(obj)) return transform(obj, (result, value, key) => set(result, camelCase(key), camelCaseKeys(value)));
  return obj;
};

/**
 * Recursively snakeCase keys of a given object.
 */
export const snakeCaseKeys = (obj) => {
  if (isArray(obj)) {
    return obj.map(snakeCaseKeys);
  }

  if (isPlainObject(obj)) {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
      const newKey = key.replace(/(\w+)|(\[(\w+)\])/g, (match, p1, p2, p3) => {
        if (p1) {
          return snakeCase(p1);
        } else if (p2) {
          return `[${snakeCase(p3)}]`;
        }
        return match;
      });

      return [newKey, snakeCaseKeys(value)];
    }));
  }

  return obj;
}

/**
 * Reject blank values from object
 */
export const rejectBlank = (obj) => (
  pickBy(obj, v => !isNil(v) && `${v}`.length)
);
