export default ({
  "pagination_unpaginated_zero": "No rows",
  "pagination_unpaginated": "{{count}} rows",
  "pagination_unpaginated_one": "{{count}} row",
  "pagination": "{{firstOfPage}} - {{lastOfPage}} in {{total}} rows",
  "close": "Close",
  "edit": "Edit",
  "delete": "Delete",
  "update": "Update",
  "add": "Add",
  "rangeDelimiter": " 〜 ",
  "dateFormat": {
    "ym": "MMM YYYY",
    "ymd": "YYYY-MM-DD",
    "ymdhm": "YYYY-MM-DD HH:mm",
    "ymdhmz": "YYYY-MM-DD HH:mm Z",
    "long": "YYYY-MM-DD (ddd)",
    "time": "h a",
    "hm": "HH:mm"
  },
  "language": {
    "ja": "Ja",
    "en": "En",
  },
  "ID": "ID",
  "hashedId": "hashed ID",
  "createdAt": "Created at",
  "updatedAt": "Updated at",
  "status": "Status",
  "revisions": "Revisions",
  "comma": ", ",
  "copied": "Copied",
  reCAPTCHA: {
    loadError: "Failed to load reCAPTCHA. Please reload the page and try again.",
  },
  dataGrid: {
    actions: "Actions",
    csv: "Download as CSV",
    share: "Copy URL to share",
    create: "Create new record",
  },
  GuestBook: {
    entryStatus: {
      ready: "Ready",
      unready: "Unready",
    },
    checkinStatus: {
      "checked-in": "Checked-In",
      "waiting": "--",
    },
  }
});
