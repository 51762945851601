import ApplicationModel from '@src/models/ApplicationModel';
import RoomGroup from '@src/models/RoomGroup';
import sortBy from 'lodash/sortBy';

export default class Property extends ApplicationModel {
  constructor(attrs = {}) {
    super(attrs);

    if (this.roomGroups) {
      this.roomGroups = sortBy(this.roomGroups, 'code').map((attr) => (
        new RoomGroup(attr).references({ property: this })
      ));
      this.rooms ||= this.roomGroups.flatMap(rg => rg.rooms);
    }
  }

  get neppanPrimaryAccount() {
    if (!this.neppanAccounts) { return }

    return this.neppanAccounts.find(a => a.enabledApis.includes('pms')) ||
      this.neppanAccounts.find(a => a.enabledApis.includes('other_checkin_ea')) ||
      this.neppanAccounts.find(a => a.enabledApis.includes('other_checkin')) ||
      this.neppanAccounts[0];
  }

  get searchString() {
    const names = [
      this.title,
      this.shortTitle,
    ].filter(v => v).flatMap(v => Object.values(v));

    return [...(new Set(names))].join(' ');
  }
}
