import ApplicationModel from '@src/models/ApplicationModel';
import Property from '@src/models/Property';
import RoomGroup from '@src/models/RoomGroup';

export default class Room extends ApplicationModel {
  static group = {};

  get groupId() {
    Room.group[this.roomGroup.id] = this.roomGroup;
    return this.roomGroup.id;
  }

  get roomGroup() {
    return this.associations.roomGroup;
  };

  set roomGroup(record) {
    this.associations.roomGroup = record;
  }

  get property() {
    return this.associations.roomGroup.property;
  };

  get searchString() {
    const names = [
      this.roomGroup?.property?.title,
      this.roomGroup?.property?.shortTitle,
      this.roomGroup?.name,
      this.name,
    ].filter(v => v).flatMap(v => Object.values(v));

    return [...(new Set(names))].join(' ');
  }

  get optionLabel() {
    return this.localized('name');
  }
}
