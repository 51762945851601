export default ({
  SidebarMenu: {
    "Dashboard": "ダッシュボード",
    "Home": "Home",
    "Reservation Management": "予約管理",
    "Reservations": "予約一覧",
    "All Reservations": "全ての予約",
    "Future Reservations": "未来の予約",
    "Staying Reservations": "滞在中の予約",
    "Room Assignments": "部屋割り",
    "Property Management": "施設管理",
    "Properties": "施設一覧",
    "User Management": "ユーザ管理",
    "Users": "ユーザ一覧",
    "Groups": "グループ一覧",
    "Housekeeping Management": "清掃管理",
    "Shift Recruitment": "シフト募集",
    "Shift Request": "シフト入力",
    "Shift Assignment": "シフト確定",
    "Shift Confirmation": "シフト確認",
    "Shift Group": "シフトグループ",
    "Room Assignment": "部屋アサイン",
    "Cleaning Report": "清掃レポート",
    "Cleaning Execution": "清掃実施",
    "Cleaning Calendar": "アサインカレンダー",
    "Contents Management": "コンテンツ管理",
    "Message Management": "メール管理",
    "Message Templates": "テンプレート",
    "Message Schedule": "スケジュール設定",
    "Message Delivery Logs": "送信履歴",
    "Unsent Reservations": "メール未送信の予約",
    "Invitation Letter Required": "案内状が必要な予約",
    "Invitation Letter Unsent": "案内状未送信の予約",
  },
  "Language": "言語設定",
});
