import { useMemo } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import useDebounced from '@src/util/useDebounced';
import { csrfToken, production } from '@src/util/environment';
import { camelCaseKeys, snakeCaseKeys } from '@src/util/object';
import ApplicationModel from '@src/models/ApplicationModel';
import Pagination from '@src/models/Pagination';

//
// NOTE: W.I.P.
//

const headers = {
  "X-CSRF-Token": csrfToken,
  "Content-Type": "application/json",
};

const fetcher = async (key, options = {}) => {
  const { arg: { body, ...params } = {}} = options;
  let url;

  if (Array.isArray(key)) {
    let [path, query] = key;
    url = `${path}?${new URLSearchParams(snakeCaseKey(query))}`;
  } else if (options.query || params.query) {
    url = `${key}?${new URLSearchParams(snakeCaseKeys(options.query || params.query))}`;
  } else {
    url = key;
  }

  return await fetch(url, {
    headers,
    body: body && JSON.stringify(snakeCaseKeys(body)),
    ...params
  }).then(async (res) => {
    if (!res.ok) {
      const error = new Error(res.statusText);

      try {
        error.info = camelCaseKeys(await res.json());
      } catch(e) { }

      error.status = res.status;
      error.response = res;
      throw error;
    }

    const Model = options.model || ApplicationModel;
    let data = camelCaseKeys(await res.json());
    const pagination = res.headers.get('x-pagination');

    if (data) {
      data = Array.isArray(data) ? data.map((d) => new Model(d)) : new Model(data);
    }

    if (pagination) {
      data.metadata ||= {}
      data.metadata.pagination = new Pagination(
        camelCaseKeys(JSON.parse(pagination))
      );
    }

    return data;
  });
};

/**
 * Response handler to format useSWR and useSWRMutation response
 */
const defaultMiddleware = (useSWRNext) => {
  return (key, fetcher, { model, query, debounce, ...config } = {}) => {
    const extendedFetcher = (key, options) => fetcher(key, { model, query, ...options });

    if (debounce) {
      key = useDebounced(key, debounce);
    }

    const response = useSWRNext(
      key,
      extendedFetcher,
      { keepPreviousData: true, ...config }
    );

    let { error } = response;
    if (error && !production) { console.error(error); }

    Object.defineProperty(response, 'pagination', {
      get() { return response.data?.metadata?.pagination || new Pagination; }
    });

    return response;
  };
};

/**
 * useSWR wrapper
 */
export const useAPI = (key, { use = [], ...config } = {}) => {
  return useSWR(key, fetcher, {
    use: [defaultMiddleware].concat(use),
    ...config
  });
};

/**
 * useSWRMutation wrapper
 */
export const useMutationAPI = (key, { use = [], ...config } = {}) => {
  return useSWRMutation(key, fetcher, {
    use: [defaultMiddleware].concat(use),
    ...config
  });
};
